import './Page.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {Box, Container, Stack} from "@mui/material";
import logo from "../images/logo1024.png";

export default function Page(props) {
    const {children, branded} = props;
    return (<Container width="100%" height="100%" className="page">
        <Box alignItems="center" justifyContent="center">
            <Stack spacing={2} alignItems="center" className="pageStack">
                {branded && <img src={logo} className="brandedPageLogo" alt="logo"/>}
                <Container className="pageContent">
                    {children}
                </Container>
            </Stack>
        </Box>
    </Container>);
}
