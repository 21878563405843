import './UploadPage.css';

import * as React from "react";
import Page from "./Page";
import {Box} from "@mui/material";
import FileDropZone from "../components/FileDropZone";
import api from "../api";

export default function UploadPage(props) {
    const {hash} = props;

    const onUploadFiles = React.useCallback(async (files, onProgress, onComplete) => {
        return api.upload(hash, files, onProgress, onComplete)
            .catch((error) => {
                console.error('api.upload() error', error);
            });
    }, [hash]);

    return (<Page branded>
        <Box className="UploadFileSelector" width="100%" alignItems="center">
            <FileDropZone onUploadFiles={onUploadFiles}/>
        </Box>
    </Page>);
}