import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PropTypes from "prop-types";

import {JsonViewer} from '@textea/json-viewer'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Button,
    DialogActions,
    DialogContent,
    Link,
    Stack
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function LinkDialog(props) {
    const {onClose, open, linkData} = props;

    if (!linkData) return <></>;

    const url = `${window.location.origin}/${linkData.hash}`;

    const handleUrlCopyToClipboard = async () => {
        await navigator.clipboard.writeText(url);
    };

    const handleHashCopyToClipboard = async () => {
        await navigator.clipboard.writeText(linkData.hash);
    };

    const handleClose = () => {
        onClose();
    };

//<AlertTitle>Success</AlertTitle>
    return (<Dialog onClose={onClose} open={open} fullWidth={true} maxWidth="md">
        <DialogTitle>Upload link created</DialogTitle>
        <DialogContent>
            <Stack spacing={2}>
                <Alert severity="success"
                       action={<Button color="inherit" size="small" onClick={handleUrlCopyToClipboard}>
                           <ContentCopyRoundedIcon/>
                       </Button>}>
                    <AlertTitle>
                        Link
                    </AlertTitle>
                    <Link target="_blank" href={url} color="inherit" variant="body2" underline="hover">{url}</Link>
                </Alert>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>View details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={1}>
                            <Alert severity="info"
                                   action={<Button color="inherit" size="small" onClick={handleHashCopyToClipboard}>
                                       <ContentCopyRoundedIcon/>
                                   </Button>}>
                                <AlertTitle>
                                    Hash
                                </AlertTitle>
                                <Typography>{linkData.hash}</Typography>
                            </Alert>
                            <Typography variant="subtitle2" gutterBottom>
                                JSON
                            </Typography>
                            <JsonViewer style={{padding: "4%"}} theme="dark" name={false}
                                        value={linkData.metadata ? linkData.metadata : {}}
                            />
                            <Typography variant="subtitle2" gutterBottom>
                                Upload path
                            </Typography>
                            <Typography>
                                <code>
                                    {linkData.path}
                                </code>
                            </Typography>
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} autoFocus>
                Close
            </Button>
        </DialogActions>
    </Dialog>);

}

LinkDialog.propTypes = {
    onClose: PropTypes.func.isRequired, open: PropTypes.bool.isRequired, linkData: PropTypes.any.isRequired
};
