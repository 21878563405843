import './LoginPage.css';

import * as React from "react";

import Page from "./Page";
import {Button, Divider, FormGroup, Stack, TextField} from "@mui/material";
import PasswordTextField from "../components/PasswordTextField";
import PropTypes from "prop-types";


function prettyLabel(fieldName) {
    let str = fieldName.trim().replace('_', ' ').toLowerCase();
    str = str.charAt(0).toUpperCase() + str.slice(1);
    return str;
}


LoginPage.propTypes = {
    passwordRequired: PropTypes.bool,
    onLogin: PropTypes.func.isRequired,
    requiredFields: PropTypes.array,
    optionalFields: PropTypes.array
};

export default function LoginPage({
                                      passwordRequired,
                                      onLogin,
                                      requiredFields,
                                      optionalFields,
                                  }) {

    const [passwordError, setPasswordError] = React.useState(false);
    const [password, setPassword] = React.useState(undefined);
    const [fieldValues, setFieldValues] = React.useState({});

    const handlePasswordChange = async (password) => {
        setPassword(password);
        if (!password.length) {
            setPasswordError(false);
        }
    }

    const onLoginClick = async () => {
        if (onLogin) {
            await onLogin(password, fieldValues)
                .then((authorized) => {
                    setPasswordError(authorized ? false : 'Invalid password');
                })
                .catch((error) => {
                    setPasswordError(error.message || error);
                });
        }
    };

    const handlePasswordKeyDown = async (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            await onLoginClick();
        }
    };

    const handleFieldChange = async (fieldName: String, required: boolean, event: React.ChangeEvent<HTMLInputElement>) => {
        fieldValues[fieldName] = event.target.value;
        setFieldValues({...fieldValues});
    }


    return (<Page branded>
        <Stack spacing={2} alignItems="center" className="passwordPrompt">
            <h1>
                {passwordRequired && !(requiredFields || optionalFields) ? "Login required" : ""}
                {passwordRequired && (requiredFields || optionalFields) ? "Password and additional information required" : ""}
                {!passwordRequired && (requiredFields || optionalFields) ? "Additional information requested" : ""}

            </h1>
            {passwordRequired && <>
                <FormGroup sx={{rowGap: 1}} className="login-group password-fields">
                    <PasswordTextField
                        password={password}
                        onPasswordChange={handlePasswordChange}
                        onKeyDown={handlePasswordKeyDown}
                        passwordError={passwordError ? passwordError : undefined}
                        sx={{width: '50ch'}}
                        label="Password"
                        autoFocus={true}
                    />
                </FormGroup>
            </>
            }
            {requiredFields &&
                <FormGroup sx={{rowGap: 1}} className="login-group required-fields">
                    <Divider>Required</Divider>
                    {requiredFields.map((fieldName, index) => {
                        return <TextField key={`required-field-${index}`} label={prettyLabel(fieldName)}
                                          sx={{width: '50ch'}} required={true}
                                          value={fieldValues[fieldName] ? fieldValues[fieldName] : ''}
                                          onChange={async (ev) => handleFieldChange(fieldName, true, ev)}/>
                    })
                    }
                </FormGroup>
            }
            {(optionalFields) &&
                <FormGroup sx={{rowGap: 1}} className="login-group optional-fields">
                    <Divider>Optional</Divider>
                    {optionalFields.map((fieldName, index) => {
                        return <TextField key={`optional-field-${index}`} label={prettyLabel(fieldName)}
                                          sx={{width: '50ch'}} required={false}
                                          value={fieldValues[fieldName] ? fieldValues[fieldName] : ''}
                                          onChange={async (ev) => handleFieldChange(fieldName, false, ev)}/>
                    })}
                </FormGroup>
            }
            <Button sx={{width: '25ch'}}
                    variant="outlined"
                    onClick={onLoginClick}>
                Login
            </Button>
        </Stack>
    </Page>);
}