import './ForbiddenPage.css';

import forbiddenIcon from "../images/access-denied-forbidden-icon.svg";
import * as React from "react";
import Page from "./Page";

export default function ForbiddenPage(props) {
    return (<Page>
        <div className="center">
            <img src={forbiddenIcon} className="forbiddenIcon swing" alt="forbidden"/>
        </div>
    </Page>);
}