import './LinkPage.css';

import * as React from 'react';
import Page from "./Page";
import {Divider, Fab, FormControlLabel, FormGroup, Stack, Switch, TextField} from "@mui/material";
import PasswordTextField from "../components/PasswordTextField";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import {AddLink} from "@mui/icons-material";
import LinkDialog from "../components/LinkDialog";
import api from "../api";
import {useSnackbar} from 'notistack';
import Typography from "@mui/material/Typography";


export default function LinkPage() {

    const {enqueueSnackbar} = useSnackbar();

    const [linkData, setLinkData] = React.useState();
    const [linkDialogOpen, setLinkDialogOpen] = React.useState(false);

    const [isPasswordEnabled, setPasswordEnabled] = React.useState(false);
    const [isExpirationEnabled, setExpirationEnabled] = React.useState(true);
    const [useDynamicPath, setUseDynamicPath] = React.useState(true);

    const [sourceId, setSourceId] = React.useState();
    const [groupId, setGroupId] = React.useState();
    const [path, setPath] = React.useState();
    const [password, setPassword] = React.useState();
    const [verifyPassword, setVerifyPassword] = React.useState();
    const [expirationDate, setExpirationDate] = React.useState(moment().add(1, "year").startOf("day"));
    const [requiredFields, setRequiredFields] = React.useState();
    const [optionalFields, setOptionalFields] = React.useState();


    const handleUseDynamicPathChange = (event: React.ChangeEvent<HTMLInputElement>) => setUseDynamicPath(event.target.checked);
    const handlePasswordEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => setPasswordEnabled(event.target.checked);
    const handleExpirationEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => setExpirationEnabled(event.target.checked);
    const handleExpirationDateChange = (value) => setExpirationDate(value);

    const handleGroupIdChange = (event: React.ChangeEvent<HTMLInputElement>) => setGroupId(event.target.value);
    const handleSourceIdChange = (event: React.ChangeEvent<HTMLInputElement>) => setSourceId(event.target.value);
    const handlePathChange = (event: React.ChangeEvent<HTMLInputElement>) => setPath(event.target.value);
    const handleRequiredFieldsChange = (event: React.ChangeEvent<HTMLInputElement>) => setRequiredFields(event.target.value);
    const handleOptionalFieldsChange = (event: React.ChangeEvent<HTMLInputElement>) => setOptionalFields(event.target.value);

    const handleCreateClick = React.useCallback(async () => {

        const newMetadata = {
            path: path && (!useDynamicPath) ? path : undefined,
            group_id: groupId && useDynamicPath ? groupId : undefined,
            source_id: sourceId && useDynamicPath ? sourceId : undefined,
            password: password && isPasswordEnabled ? password : undefined,
            expires: expirationDate && isExpirationEnabled ? moment(expirationDate).startOf('day').format('YYYY-MM-DD') : undefined,
            required_fields: requiredFields ? requiredFields : undefined,
            optional_fields: optionalFields ? optionalFields : undefined
        };

        await api.hash(newMetadata).then((linkData) => {
            setLinkData(linkData);
            setLinkDialogOpen(true);
        }).catch((error) => {
            enqueueSnackbar((error.message || '' + error), {variant: "error"});
        });

    }, [enqueueSnackbar, setLinkData, useDynamicPath, path, sourceId, groupId, password, expirationDate, isPasswordEnabled,
        isExpirationEnabled, requiredFields, optionalFields, setLinkDialogOpen]);

    const handleLinkDialogClose = () => {
        setLinkDialogOpen(false);
    }

    let passwordError = false;
    if (password && verifyPassword && password !== verifyPassword) {
        passwordError = true;
    }

    return (<>
        <div className="floatingAction">
            <Fab variant="extended" color="primary" aria-label="create" onClick={handleCreateClick}>
                <AddLink sx={{mr: 1}}/>
                Create link
            </Fab>
        </div>
        <LinkDialog open={linkDialogOpen} linkData={linkData} onClose={handleLinkDialogClose}/>
        <Page branded>

            <Stack spacing={2} width="100%">
                <h1>
                    Create upload link
                </h1>
                <FormGroup sx={{rowGap: 1}} className="link-group">
                    <Divider>Upload path</Divider>
                    <FormControlLabel control={<Switch checked={useDynamicPath} onChange={handleUseDynamicPathChange}/>}
                                      label="Use dynamic path"/>
                    <Typography>Dynamic path parameters <small>(comma separated)</small></Typography>
                    <TextField fullWidth disabled={!useDynamicPath} label="Group ID" value={groupId}
                               onChange={handleGroupIdChange}/>
                    <TextField fullWidth disabled={!useDynamicPath} label="Source ID" value={sourceId}
                               onChange={handleSourceIdChange}/>
                    <Typography>Path</Typography>
                    <TextField fullWidth disabled={useDynamicPath} label="Path" value={path}
                               onChange={handlePathChange}/>
                </FormGroup>

                <FormGroup sx={{rowGap: 1}} className="link-group">
                    <Divider>Password protection</Divider>
                    <FormControlLabel
                        control={<Switch checked={isPasswordEnabled} onChange={handlePasswordEnabledChange}/>}
                        label="Require password"/>
                    <PasswordTextField fullWidth disabled={!isPasswordEnabled} password={password}
                                       label="Password"
                                       onPasswordChange={setPassword}/>
                    <PasswordTextField fullWidth disabled={!isPasswordEnabled} password={verifyPassword}
                                       label="Verify password"
                                       onPasswordChange={setVerifyPassword}
                                       passwordError={isPasswordEnabled && passwordError ? "Passwords do not match" : false}/>
                </FormGroup>

                <FormGroup sx={{rowGap: 1}} className="link-group">
                    <Divider>Expiration</Divider>
                    <FormControlLabel
                        control={<Switch checked={isExpirationEnabled} onChange={handleExpirationEnabledChange}/>}
                        label="Expiration enabled"/>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            disabled={!isExpirationEnabled}
                            label="Expiration date"
                            inputFormat="YYYY/MM/DD"
                            value={expirationDate}
                            onChange={handleExpirationDateChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </FormGroup>

                <FormGroup sx={{rowGap: 1}} className="link-group">
                    <Divider>Additional fields</Divider>
                    <Typography>Fields required before upload <small>(comma separated)</small></Typography>
                    <TextField fullWidth label="Required fields" value={requiredFields}
                               onChange={handleRequiredFieldsChange}/>
                    <Typography>Optional fields before upload <small>(comma separated)</small></Typography>
                    <TextField fullWidth label="Optional fields" value={optionalFields}
                               onChange={handleOptionalFieldsChange}/>
                </FormGroup>

            </Stack>
        </Page></>);
}