import './PasswordTextField.css';

import * as React from "react";

import {IconButton, InputAdornment, TextField} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import PropTypes from "prop-types";


PasswordTextField.propTypes = {
    disabled: PropTypes.bool,
    passwordError: PropTypes.string,
    onPasswordChange: PropTypes.func,
    password: PropTypes.string
};

export default function PasswordTextField({disabled, passwordError, onPasswordChange, password, ...props}) {

    const [showPassword, setShowPassword] = React.useState(false);
    const [localPassword, setLocalPassword] = React.useState(onPasswordChange ? password : '');

    const handleClickShowPassword = async () => {
        setShowPassword(!showPassword);
    };

    const handlePasswordChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const password = event.target.value;
        if (onPasswordChange) {
            onPasswordChange(password);
        } else {
            setLocalPassword(password);
        }
    };

    const handleMouseDownPassword = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (<TextField
        className={(!disabled) && passwordError ? 'shake' : null}
        type={showPassword ? 'text' : 'password'}
        value={(!disabled) ? (onPasswordChange ? password : localPassword) : ''}
        onChange={handlePasswordChange}
        error={(!disabled) && passwordError ? passwordError : undefined}
        helperText={(!disabled) && passwordError ? passwordError : ''}
        InputProps={{
            endAdornment: <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                </IconButton>
            </InputAdornment>
        }}
        {...props}/>);

}