import './FileTable.css';

import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import prettyBytes from "pretty-bytes";
import {red} from '@mui/material/colors';
import DoNotDisturbRoundedIcon from '@mui/icons-material/DoNotDisturbRounded';

import CircularProgressWithLabel from './CircularProgressWithLabel';

const headCells = [{
    id: 'filename', align: 'left', label: 'File',
}, {
    id: 'size', align: 'right', label: 'Size',
}, {
    id: 'progress', align: 'center', label: 'Progress',
}];

function FileTableHead() {
    return (<TableHead>
        <TableRow>
            {headCells.map((headCell) => (<TableCell
                key={headCell.id}
                align={headCell.align}
                padding={'normal'}
            >
                {headCell.label}
            </TableCell>))}
        </TableRow>
    </TableHead>);
}


export default function FileTable(props) {

    const {files} = props;
    return (<Box sx={{width: '100%'}}>
        <Paper sx={{width: '100%', mb: 2}}>

            <TableContainer>
                <Table>
                    <FileTableHead rowCount={files.length}/>
                    <TableBody>
                        {files.sort((a, b) => {
                            return b.index - a.index
                        }).map((file, index) => {
                            const labelId = `file-table-checkbox-${index}`;
                            return (<TableRow
                                hover
                                tabIndex={-1}
                                key={`file-row-${file.name}_${index}`}>
                                <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="normal"
                                >
                                    {file.path[0] === '/' || file.path[0] === '\\' ? file.path.slice(1) : file.path}
                                </TableCell>
                                <TableCell align="right">
                                    {prettyBytes(file.size)}
                                </TableCell>
                                <TableCell align="center">
                                    {file.error !== false ? <DoNotDisturbRoundedIcon sx={{color: red[500]}}/> :
                                        <CircularProgressWithLabel progress={file.progress}/>}
                                </TableCell>
                            </TableRow>);
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    </Box>);
}