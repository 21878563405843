import './CircularProgressWithLabel.css';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {CircularProgress, CircularProgressProps} from "@mui/material";
import {green} from '@mui/material/colors';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

function CircularProgressWithLabel(props: CircularProgressProps & { progress: number }) {
    return (<Box sx={{position: 'relative', display: 'inline-flex'}}>
        <CircularProgress variant="determinate" {...props} value={props.progress * 100} sx={{color: green[500]}}/>
        <Box
            sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {props.progress >= 1 ? <CheckCircleRoundedIcon sx={{color: green[500]}}/> : <Typography
                variant="caption"
                component="div"
                fontSize="0.6rem"
                color="text.secondary"
            >{`${Math.round(props.progress * 100)}%`}</Typography>}
        </Box>
    </Box>);
}

export default CircularProgressWithLabel;