import './UrlHashPage.css';

import * as React from 'react';

import {CircularProgress} from "@mui/material";

import {useParams} from "react-router-dom";

import api from "../api.js";

import ForbiddenPage from "./ForbiddenPage";
import UploadPage from "./UploadPage";
import LoginPage from "./LoginPage";

const BusyIndicator = (props) => {
    return (<div className="center">
        <CircularProgress/>
    </div>);
}

export default function UrlHashPage(props) {
    const hash = useParams().hash;
    const [isDenied, setDenied] = React.useState(false);
    const [isAuthorized, setAuthorized] = React.useState(null);
    const [requiredFields, setRequiredFields] = React.useState(false);
    const [optionalFields, setOptionalFields] = React.useState(false);
    const [hasAllRequiredFields, setHasAllRequiredFields] = React.useState(false);

    const updateApiStatus = async (apiStatus) => {
        if (apiStatus.startsWith('authorized')) {
            setAuthorized(true);
            setDenied(false);
            if (apiStatus === 'authorized') {
                setHasAllRequiredFields(true);
            } else {
                setHasAllRequiredFields(false);
            }
            return true;
        } else if (apiStatus.startsWith('unauthorized')) {
            setAuthorized(false);
            setDenied(false);
            if (apiStatus === 'unauthorized') {
                setHasAllRequiredFields(true);
            } else {
                setHasAllRequiredFields(false);
            }
            return false;
        } else {
            console.error('API response:', apiStatus);
            setAuthorized(false);
            setDenied(true);
            setHasAllRequiredFields(false);
            return false;
        }
    };

    const onLogin = React.useCallback(async (password, additionalFields = false) => {
        return api.login(hash, password, additionalFields)
            .then(async response => {
                setRequiredFields(response.required_fields ? response.required_fields.split(',') : false);
                setOptionalFields(response.optional_fields ? response.optional_fields.split(',') : false);
                return await updateApiStatus(response.status);
            })
            .catch((error) => {
                console.error('api.authenticate() error', error);
                updateApiStatus(error);
            });
    }, [hash, setRequiredFields, setOptionalFields]);

    React.useEffect(() => {
        (async () => {
            if (isAuthorized === null && hash && hash.length) {
                await onLogin();
            }
        })();
    }, [isAuthorized, hash, onLogin]);

    if (!hash || !hash.length || isDenied === true) {
        return ForbiddenPage();
    }

    if (isAuthorized === null) {
        return BusyIndicator();
    }

    if (isAuthorized === true && hasAllRequiredFields === true) {
        return <UploadPage hash={hash}/>;
    }

    return <LoginPage passwordRequired={!isAuthorized} onLogin={onLogin} requiredFields={requiredFields}
                      optionalFields={optionalFields}/>;

}
