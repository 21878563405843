import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import reportWebVitals from './reportWebVitals';

import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";

import ErrorPage from "./pages/ErrorPage";
import UrlHashPage from "./pages/UrlHashPage";
import LinkPage from "./pages/LinkPage";
import ForbiddenPage from "./pages/ForbiddenPage";
import {Container} from "@mui/material";
import Box from "@mui/material/Box";

import {SnackbarProvider} from 'notistack';

function Root() {
    return (<Container className="root">
        <SnackbarProvider maxSnack={3}>
            <Box width="80wh" margin="0" paddingTop="2%" paddingBottom="2%">
                <Outlet/>
            </Box>
        </SnackbarProvider>
    </Container>);
}

const router = createBrowserRouter([{
    path: "/", element: <Root/>, errorElement: <ErrorPage/>, children: [{
        path: "/", element: <ForbiddenPage/>
    }, {
        path: "/link", element: <LinkPage/>
    }, {
        path: "/:hash", element: <UrlHashPage/>
    }]
},]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>
    <RouterProvider router={router}/>
</React.StrictMode>);

// If you want to start measuring performance in your server, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
