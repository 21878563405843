import './FileDropZone.css';

import React from 'react';
import {useDropzone} from 'react-dropzone';
import {Button, Container, Stack} from "@mui/material";
import uploadIcon from "../images/security_upload_icon.png";

import FileTable from "./FileTable";
import Box from "@mui/material/Box";

const fileKey = (file) => {
    return `${file.name}_${file.lastModified}_${file.size}`;
}

export default function FileDropZone(props) {

    const {onUploadFiles} = props;

    const [index, setIndex] = React.useState(0);
    const [files, setFiles] = React.useState(new Map());

    const onAddFiles = React.useCallback(async (acceptedFiles) => {
        [...acceptedFiles].forEach(f => {
            const key = fileKey(f);
            if (!files.has(key)) {
                f.index = index;
                f.progress = 0;
                f.error = false;
                f.uploaded = false;
                files.set(key, f);
                setIndex(index + 1);
            }
        });
        setFiles(new Map(files));
        return onUploadFiles([...files.values()], async (file) => {
            setFiles(new Map(files));
        });
    }, [index, setIndex, files, setFiles, onUploadFiles]);

    const {getRootProps, getInputProps} = useDropzone({onDropAccepted: onAddFiles});

    return (<Container>
        <Stack className="FileDropZone" spacing={2}>
            <Box bgcolor="#fafafa" border="1px dashed lightgray" padding="10px"
                 textAlign="center" {...getRootProps({className: 'dropzone'})}>
                <img src={uploadIcon} className="uploadIcon" alt="Upload"/>
                <input {...getInputProps()} />
                <p>
                    Drag and drop files here, or <Button variant="outlined">Browse files</Button>
                </p>
            </Box>
            {files.size > 0 && <FileTable files={[...files.values()]}/>}
        </Stack>
    </Container>);
}
