import axios from 'axios';

import pLimit from 'p-limit';

const limit = pLimit(4);

const login = async (hash, password = undefined, additionalFields = undefined) => {
    return axios.post(`/api/auth/${hash}`, {
        fields: additionalFields || undefined
    }, {
        auth: (password ? {
            password: password
        } : undefined), responseType: 'json'
    }).then(response => {
        console.log('api.authenticate: success: ', hash, response.data);
        return response.data;
    }).catch(error => {
        console.error('api.authenticate: error: ', hash, error.response.data);
        return {status: 'error', ...error.response.data};
    });
}

const hash = async (metadata) => {
    return axios.post('/api/hash', metadata, {
        responseType: 'json'
    }).then(response => {
        if (response.data.status === 'ok') {
            console.log('api.hash: ok: ', metadata, response.data);
            return response.data;
        } else {
            throw new Error(response.data);
        }
    }).catch(error => {
        console.error('api.hash: failed: ', metadata, error.response.data);
        throw new Error(error.response.data.status || error.response.data || error.message || '' + error);
    });
}

const upload = async (hash, files, onUploadProgress) => {
    const promises = [];
    for (let file of files) {
        if (file.uploaded === true) {
            continue;
        }
        let filename = file.path[0] === '/' || file.path[0] === '\\' ? file.path.slice(1) : file.path;
        const formData = new FormData();
        formData.append('file', file, filename);
        file.progress = 0;
        file.error = false;
        file.uploaded = true;
        promises.push(limit(async () => {

            // Verify filename
            return await axios.post(`/api/upload/${hash}/verify`, {
                filename: filename
            }).then(async response => {


                // Upload file if accepted
                await axios.post(`/api/upload/${hash}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data; charset=utf-8'
                    }, onUploadProgress: onUploadProgress ? (progressEvent) => {
                        file.progress = progressEvent.progress;
                        file.error = false;
                        onUploadProgress(file);
                    } : undefined
                })
                    .then(() => {
                        if (onUploadProgress) {
                            file.progress = 1;
                            file.error = false;
                            onUploadProgress(file);
                        }
                    })

            }).catch(error => {
                console.error('api.upload: verify: rejected: ', filename, error.response.data.error);
                if (onUploadProgress) {
                    file.error = error.response.data.error || 'rejected by server';
                    onUploadProgress(file);
                }
            });


        }));
    }
    return Promise.allSettled(promises);
}

const api = {
    login, hash, upload
};

export default api;



